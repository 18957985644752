import React, { useEffect, useState } from "react";
import AgingLineChart from "../../components/Charts/AgingLineChart";
import OpenCreditLineChart from "../../components/Charts/OpenCreditLineChart";
import ApiService from "../../services/ApiService";
import { PageSection } from "../../ui-components/Container";
import Placeholder from "../../ui-components/Placeholder";

const fetchHistory = (setData) => {
  ApiService.getApi("analytics/history")
    .then((res) => {
      setData(res);
    })
    .catch(() => {
      console.error("Error when fetching history");
    });
};

const History = ({ start }) => {
  const [data, setData] = useState();

  useEffect(() => {
    if (!data) {
      fetchHistory(setData);
    }
  }, []);

  return data ? (
    <>
      <PageSection title="Storico aging del credito">
        <AgingLineChart
          id="aging-line-chart"
          data={data.filter((d) => {
            const date = new Date(d.dat_history);
            return start.getTime() < date.getTime();
          })}
        />
      </PageSection>
      <PageSection title="Storico credito aperto">
        <OpenCreditLineChart
          id="open-credit-line-chart"
          data={data.filter((d) => {
            const date = new Date(d.dat_history);
            return start.getTime() < date.getTime();
          })}
        />
      </PageSection>
    </>
  ) : (
    <div className="flex flex-col w-full mb-4">
      <Placeholder height="h-40" classNames="mb-4 rounded-lg" />
      <Placeholder height="h-40" classNames="mb-4 rounded-lg" />
    </div>
  );
};

export default History;
