import React, { Fragment } from "react";

// UI
import { PageHeading } from "../../ui-components/Container";
import ErrorBoundary from "../../ui-components/ErrorBoundary";
import KpiList from "./KpiList";
import {
  openCredit,
  openCreditAging,
  solicitousHistorySend,
  solicitousHistorySendPaid,
  totalContracts,
  totalContractsNotReachable,
  totalSolicitousHistorySend,
  totalSolicitousHistorySendPaid,
} from "./kpis";

function Home() {
  return (
    <Fragment>
      <div>
        <PageHeading title="Home" />
      </div>
      <ErrorBoundary from="open-credit" hide>
        <KpiList
          list={[
            solicitousHistorySend,
            solicitousHistorySendPaid,
            totalSolicitousHistorySend,
            totalSolicitousHistorySendPaid,
          ]}
          apiUrl="landing/solicitous"
        />
        <KpiList list={[openCreditAging, openCredit]} apiUrl="landing/open" />
        <KpiList
          list={[totalContracts, totalContractsNotReachable]}
          apiUrl="landing/consumers"
        />
      </ErrorBoundary>
    </Fragment>
  );
}

export default Home;
