import React, { useEffect, useState } from "react";
import NotificationStackedAreaChart from "../../components/Charts/NotificationStackedAreaChart";
import ApiService from "../../services/ApiService";
import { PageSection } from "../../ui-components/Container";
import Placeholder from "../../ui-components/Placeholder";

const fetchNotification = (setData) => {
  ApiService.getApi("analytics/notification")
    .then((res) => {
      setData(res);
    })
    .catch(() => {
      console.error("Error when fetching notification");
    });
};

const Notification = ({ start }) => {
  const [data, setData] = useState();

  useEffect(() => {
    if (!data) {
      fetchNotification(setData);
    }
  }, []);

  return data ? (
    <PageSection title="Storico invii per settimana">
      <NotificationStackedAreaChart
        id="notification-stacked-area-chart"
        data={data.filter((d) => {
          const date = new Date(d.data);
          return start.getTime() < date.getTime();
        })}
      />
    </PageSection>
  ) : (
    <div className="flex flex-col w-full mb-4">
      <Placeholder height="h-40" classNames="mb-4 rounded-lg" />
    </div>
  );
};

export default Notification;
