import React, { useState, useEffect } from "react";
import _ from "lodash";

// Services

// UI
import { LinearProgress } from "@material-ui/core";
import { AddCircle } from "@material-ui/icons";
import { PageHeading } from "../../Container";

import { UserAddIcon } from "@heroicons/react/solid";
import AuthenticationService from "../../../services/AuthenticationService";
import SimpleTable from "../../SimpleTable";
import SimpleAlert from "../../../components/Alert/SimpleAlert";
import Button from "../../Button";
import ApiService from "../../../services/ApiService";
import AddRoleDialog from "./AddRoleDialog";
import Badge from "../../Badge";
import ErrorBoundary from "../../ErrorBoundary";

const COLUMNS = [
  {
    title: "ID",
    field: "id",
  },
  {
    title: "Nome",
    field: "name",
    render: ({ name }) => <b>{name}</b>,
  },
  {
    cellStyle: { width: 600, minWidth: 600 },
    headerStyle: { width: 600, minWidth: 600 },
    title: "Scopes",
    field: "scopes",
    editComponent: ({ scopes }) => <b>{scopes}</b>,
    render: ({ scopes }) =>
      !scopes ? (
        "-"
      ) : (
        <div>
          {scopes.split(";").map((s) => (
            <Badge classNames="mb-1 mr-1" text={s} key={s} />
          ))}
        </div>
      ),
  },
];

const Roles = () => {
  const [roles, setRoles] = useState();
  const [loading, setLoading] = useState(false);
  const [showNewRoleDialog, setShowNewRoleDialog] = useState(false);
  const scopes = AuthenticationService.getUserScopes();
  const [alert, setAlert] = useState({ open: false, type: "", message: "" });
  const openAlert = (type, message) => {
    setAlert({ open: true, type, message });
  };
  const toggleAlert = () => {
    setAlert({ ...alert, open: !alert.open });
  };

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        setLoading(true);
        const roles = await ApiService.getApi("roles");

        setRoles(
          roles.reduce((rolesObj, role) => _.set(rolesObj, [role.id], role), {})
        );
      } catch (error) {
        openAlert("error", "Errore imprevisto");
      } finally {
        setLoading(false);
      }
    };

    if (!roles) {
      fetchRoles();
    }
  }, [roles]);

  const toggleNewRoleDialog = () => setShowNewRoleDialog(!showNewRoleDialog);
  const canCreate = scopes.indexOf("role:create") >= 0;

  return (
    <>
      <LinearProgress style={{ visibility: loading ? "visible" : "hidden" }} />

      <PageHeading
        title="Gestione ruoli"
        contentActions={
          canCreate ? (
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddCircle />}
              onClick={toggleNewRoleDialog}
            >
              <UserAddIcon
                className="-ml-0.5 mr-2 h-4 w-4"
                aria-hidden="true"
              />
              Aggiungi
            </Button>
          ) : null
        }
      />
      <ErrorBoundary from="roles-table">
        <SimpleTable
          rows={roles}
          setRow={setRoles}
          openAlert={openAlert}
          apiKey="roles"
          roleKey="role"
          columns={COLUMNS}
        />
      </ErrorBoundary>
      {canCreate ? (
        <ErrorBoundary from="roles-add-dialog">
          <AddRoleDialog
            open={showNewRoleDialog}
            toggleOpen={toggleNewRoleDialog}
            roles={roles}
            setRoles={setRoles}
          />
        </ErrorBoundary>
      ) : null}
      <SimpleAlert alert={alert} toggleAlert={toggleAlert} />
    </>
  );
};

export default Roles;
