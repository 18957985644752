import React, { useEffect, useState } from "react";
import { PageSection } from "../../ui-components/Container";
import Placeholder from "../../ui-components/Placeholder";
import { getKpi } from "./kpi";
import Grid from "@material-ui/core/Grid";
import PaymentTreeChart from "../../components/Charts/PaymentTreeChart";
import ApiService from "../../services/ApiService";

const fetchPaymentTree = (setData) => {
  ApiService.getApi("analytics/payment-tree")
    .then((res) => {
      setData(res);
    })
    .catch(() => {
      console.error("Error when fetching notification");
    });
};

const PaymentTree = ({ periodName }) => {
  const [data, setData] = useState();

  useEffect(() => {
    if (!data) {
      fetchPaymentTree(setData);
    }
  }, []);

  return data ? (
    <PageSection title="Ripartizione tra i contenuti inviati">
      <Grid container>
        <Grid item style={{ minWidth: "80%" }}>
          {data[periodName]["tree"]["children"][0] && (
            <PaymentTreeChart
              id="payment-tree-chart-1"
              data={data[periodName]["tree"]["children"][0]}
            />
          )}
          {data[periodName]["tree"]["children"][1] && (
            <PaymentTreeChart
              id="payment-tree-chart-2"
              data={data[periodName]["tree"]["children"][1]}
            />
          )}
        </Grid>
        <Grid item>
          <div className="flex-col flex">
            {getKpi(
              "Totale Invii",
              data[periodName]["kpi"]["invii"].toLocaleString(),
              data[periodName]["kpi"]["importo"].toLocaleString()
            )}
            {getKpi(
              "Invii recapitati",
              data[periodName]["kpi"]["invii_ok"].toLocaleString(),
              data[periodName]["kpi"]["importo_ok"].toLocaleString(),
              data[periodName]["kpi"]["perc_invii_ok"].toLocaleString(),
              data[periodName]["kpi"]["perc_importo_ok"].toLocaleString()
            )}
            {getKpi(
              "Invii pagati",
              data[periodName]["kpi"]["invii_pagato"].toLocaleString(),
              data[periodName]["kpi"]["importo_pagato"].toLocaleString(),
              data[periodName]["kpi"]["perc_invii_pagato"].toLocaleString(),
              data[periodName]["kpi"]["perc_importo_pagato"].toLocaleString()
            )}
          </div>
        </Grid>
      </Grid>
    </PageSection>
  ) : (
    <div className="flex flex-col w-full mb-4">
      <Placeholder height="h-40" classNames="mb-4 rounded-lg" />
    </div>
  );
};

export default PaymentTree;
