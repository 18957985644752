import React from "react";
import Logo from "../../components/Logo";

const AuthHeader = ({ title }) => {
  return (
    <div className="sm:mx-auto sm:w-full sm:max-w-md">
      <div className="flex justify-center w-full">
        <Logo height="h-20" />
      </div>
      <h2 className="mt-6 text-center text-am-600 text-3xl font-bold text-gray-900">
        {title}
      </h2>
      <div className="mt-2 text-center text-sm text-gray-600">
        <p className="font-medium">DASHBOARD RECUPERO CREDITI</p>
      </div>
    </div>
  );
};

export default AuthHeader;
