import {
  HomeIcon,
  ChartBarIcon,
  AdjustmentsIcon,
  PhoneMissedCallIcon,
} from "@heroicons/react/outline";
import Home from "@pages/Home";
import Analytics from "@pages/Analytics";
import ControlPanel from "@pages/ControlPanel";
import UnreachableUsers from "@pages/UnreachableUsers";

const routes = [
  {
    name: "Home",
    href: "/home",
    Icon: HomeIcon,
    component: Home,
  },
  {
    name: "Analytics",
    href: "/analytics",
    Icon: ChartBarIcon,
    component: Analytics,
  },
  {
    name: "Pannello di controllo",
    href: "/control-panel",
    Icon: AdjustmentsIcon,
    component: ControlPanel,
  },
  {
    name: "Utenze non contattabili",
    href: "/unreachable-users",
    Icon: PhoneMissedCallIcon,
    component: UnreachableUsers,
  },
];

export default routes;
