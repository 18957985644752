import React, { useState } from "react";

// UI
import { PageHeading } from "../../ui-components/Container";
import Select from "../../ui-components/Select";
import ErrorBoundary from "../../ui-components/ErrorBoundary";

import History from "./History";
import Notification from "./Notification";
import PaymentTree from "./PaymentTree";

const lastYear = new Date();
lastYear.setFullYear(lastYear.getFullYear() - 1);

const lastTwoYear = new Date();
lastTwoYear.setFullYear(lastTwoYear.getFullYear() - 2);

const lastTwoWeeks = new Date();
lastTwoWeeks.setDate(lastTwoWeeks.getDate() - 14);

const lastMonth = new Date();
lastMonth.setMonth(lastMonth.getMonth() - 1);

const lastThreeMonth = new Date();
lastThreeMonth.setMonth(lastThreeMonth.getMonth() - 3);

const lastYearObject = {
  name: "Ultimo anno",
  value: {
    periodName: "ultimo anno",
    start: lastYear,
  },
};

const periods = [
  {
    name: "Ultime due settimane",
    value: {
      periodName: "ultime due settimane",
      start: lastTwoWeeks,
    },
  },
  {
    name: "Ultimo mese",
    value: {
      periodName: "ultimo mese",
      start: lastMonth,
    },
  },
  {
    name: "Ultimo trimestre",
    value: {
      periodName: "ultimo trimestre",
      start: lastThreeMonth,
    },
  },
  lastYearObject,
  {
    name: "Ultimi due anni",
    value: {
      periodName: "ultimi due anni",
      start: lastTwoYear,
    },
  },
];

function Analytics() {
  const [period, setPeriod] = useState(lastYearObject);

  const {
    value: { periodName, start },
  } = period;

  return (
    <ErrorBoundary from="analytics" hide>
      <div>
        <PageHeading
          title="Analytics"
          contentActions={
            <Select
              options={periods.map((i) => ({
                value: i.value.periodName,
                name: i.name,
              }))}
              value={periodName}
              onChange={(e) => {
                const p = periods.find(
                  (p) => p.value.periodName === e.target.value
                );
                setPeriod(p);
              }}
              label="Orizzonte temporale"
            />
          }
        />
        <History start={start} />
        <Notification start={start} />
        <PaymentTree periodName={periodName} />
      </div>
    </ErrorBoundary>
  );
}

export default Analytics;
