import React, { Fragment, useState } from "react";
import Container from "@material-ui/core/Container";
import AuthenticationService from "@services/AuthenticationService";
import axios from "axios";
import { API_URL } from "@utils/constants";
import { Redirect } from "react-router";
import Input from "../../Input";
import Button from "../../Button";
import Alert from "../../Alert";
import Modal from "../../Modal";
import AuthHeader from "../../layout/AuthHeader";
import PoweredBy from "../../layout/PoweredBy";

function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [openForgot, setOpenForgot] = useState(false);
  const [error, setError] = useState("");
  const [errorForgot, setErrorForgot] = useState("");
  const [email, setEmail] = useState("");
  const [emailSent, setEmailSent] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const setOnError = (msg) => setError(msg);
  const setOffError = () => setError("");
  const setOnErrorForgot = (msg) => setErrorForgot(msg);
  const setOffErrorForgot = () => setErrorForgot("");

  function toggleForgot() {
    setEmailSent(false);
    setOpenForgot(!openForgot);
  }

  async function recoverPassword(email) {
    return await axios
      .post(`${API_URL}/users/password/forgot`, { email })
      .then(() => {
        setOffErrorForgot();
        setEmailSent(true);
      })
      .catch((err) => {
        setOnErrorForgot("Email non registrata");
      });
  }

  const handleRecoverPassword = async (e) => {
    e.preventDefault();
    setLoading(true);

    await recoverPassword(email);
    setLoading(false);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    setOffError();
    await AuthenticationService.login(username, password);

    if (AuthenticationService.isUserAuthenticated()) {
      window.location = "/home";
    } else {
      setOnError("Email o Password non corretti!");
      setLoading(false);
      setPassword("");
    }
  };

  if (AuthenticationService.isUserAuthenticated()) {
    return <Redirect to="/home" />;
  }

  return (
    <Fragment>
      <div className="bg-gray-100 min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <Container>
          <AuthHeader title="Entra nel tuo account" />

          <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
              <form action="#" method="POST" onSubmit={handleLogin}>
                <div>
                  <Input
                    value={username}
                    onChange={(e) => {
                      setUsername(e.target.value);
                    }}
                    label="Email"
                    type="email"
                    required
                    errorMessage="Inserisci email"
                  />
                </div>

                <div>
                  <Input
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    label="Password"
                    type={showPassword ? "text" : "password"}
                    required
                  />
                </div>

                <div className="flex items-center justify-between mb-6">
                  <div className="flex items-center">
                    <input
                      id="remember-me"
                      name="remember-me"
                      type="checkbox"
                      className="h-4 w-4 text-am-600 focus:ring-am-500 border-gray-300 rounded"
                      onChange={(e) => {
                        setShowPassword(e.target.checked);
                      }}
                    />
                    <label
                      htmlFor="remember-me"
                      className="ml-2 block text-sm text-gray-900"
                    >
                      Mostra password
                    </label>
                  </div>
                  <div className="text-sm">
                    <p
                      onClick={toggleForgot}
                      className="font-medium text-am-600 hover:text-am-500 cursor-pointer"
                    >
                      Password dimenticata?
                    </p>
                  </div>
                </div>

                <div>
                  <Button full type="submit" submitting={loading}>
                    Sign in
                  </Button>
                </div>
                {error ? (
                  <div className="mt-4">
                    <Alert slim type="error" title={error} />
                  </div>
                ) : null}
              </form>
            </div>
            <PoweredBy />
          </div>
        </Container>
      </div>
      {openForgot ? (
        <Modal onExit={() => toggleForgot()}>
          <div className="w-full flex-col flex items-center my-6">
            <h3 className=" text-center text-2xl font-extrabold text-gray-900">
              Recupera password
            </h3>
            <p className="mt-2 text-center text-sm text-gray-600 mb-4">
              <p className="font-medium">
                Inserisci l'indirizzo email associato alla tua utenza
              </p>
            </p>
            <form
              action="#"
              method="POST"
              className="w-full max-w-sm"
              onSubmit={handleRecoverPassword}
            >
              <div className="my-4">
                <Input
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  label="Email"
                  type="email"
                  required
                />
              </div>

              <div>
                <Button full type="submit" submitting={loading}>
                  Invia password
                </Button>
              </div>
              {errorForgot ? (
                <div className="mt-4">
                  <Alert slim type="error" title={errorForgot} />
                </div>
              ) : null}
              {emailSent ? (
                <div className="mt-4">
                  <Alert
                    slim
                    type="success"
                    title={`Email inviata con successo a ${email}`}
                  />
                </div>
              ) : null}
            </form>
          </div>
        </Modal>
      ) : null}
    </Fragment>
  );
}

export default Login;
