import axios from "axios";
import { API_URL } from "../../utils/constants";
import jwtDecode from "jwt-decode";

class AuthenticationService {
  static AUTH_STORAGE_PATH = "logged_user";
  static AUTH_TIME = 2 * 3600 * 1000; // 2h

  static getUserData() {
    const authObj = JSON.parse(localStorage.getItem(this.AUTH_STORAGE_PATH));

    if (authObj) {
      return authObj;
    }
    return undefined;
  }

  static getUserScopes() {
    const token = this.getAuthToken();

    if (token) {
      const { scopes } = jwtDecode(token);

      return scopes;
    }
    return [];
  }

  static getAuthToken() {
    const authObj = JSON.parse(localStorage.getItem(this.AUTH_STORAGE_PATH));

    if (authObj && authObj.access_token) {
      if (
        (authObj.expires && authObj.expires_at > Date.now()) ||
        !authObj.expires
      )
        return authObj.access_token;

      this.deleteAuthToken();
      return undefined;
    }
    return undefined;
  }

  static setAuthToken(token, userData, expires = true) {
    const now = Date.now();
    const expires_at = expires ? now + this.AUTH_TIME : -1;

    const authObj = {
      ...userData,
      access_token: token,
      expires,
      expires_at,
    };

    localStorage.setItem(this.AUTH_STORAGE_PATH, JSON.stringify(authObj));
  }

  static deleteAuthToken() {
    localStorage.removeItem(this.AUTH_STORAGE_PATH);
  }

  static async login(username, password) {
    const data = new FormData();
    data.append("username", username);
    data.append("password", password);

    return await axios
      .post(`${API_URL}/auth/login`, data)
      .then((res) => {
        try {
          const token = res.data["access_token"];
          const userData = res.data["user_data"];
          if (token) {
            this.setAuthToken(token, userData);
          }
        } catch (err) {
          console.log("Login failed.", err);
        }
        return true;
      })
      .catch((err) => {
        console.log("Login failed.", err);
      });
  }

  static logout() {
    this.deleteAuthToken();
  }

  static isUserAuthenticated() {
    const access_token = this.getAuthToken();
    return !!access_token;
  }

  static authHeader() {
    if (this.isUserAuthenticated())
      return {
        Authorization: `Bearer ${this.getAuthToken()}`,
      };

    return false;
  }
}

export default AuthenticationService;
