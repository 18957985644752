const openFiltreableColumns = [
  { key: "aging_bin", title: "Aging bin" },
  { key: "classe_cliente", title: "Classe cliente" },
  { key: "classe_fornitura", title: "Classe fornitura" },
  { key: "scadere_scaduto", title: "Scaduto/Scadere" },
];

const contractsFiltreableColumns = [
  { key: "desc_classe_cliente", title: "Classe cliente" },
  { key: "classe_fornitura", title: "Classe fornitura" },
];

const openCreditAging = {
  id: "open-credit-aging",
  title: "Aging medio del credito aperto",
  description: "Media pesata del credito aperto",
  valueTitle: "Giorni",
  filterableColumns: openFiltreableColumns,
  getKpiValue: (data) => {
    if (!data || !data.length) {
      return 0;
    }

    let result = data.map(({ n_fatture, sum_aging }) => sum_aging / n_fatture);
    result = Math.ceil(
      result.reduce((acc, value) => acc + value, 0) / result.length
    );

    return result;
  },
};

const openCredit = {
  id: "open-credit",
  title: "Totale del credito aperto",
  description: "Somma del credito aperto",
  valueTitle: "Valore",
  filterableColumns: openFiltreableColumns,
  getKpiValue: (data) => {
    if (!data || !data.length) {
      return 0;
    }

    const result = data.reduce(
      (acc, row) => acc + row.sum_importo_parz_res_da_pagare,
      0
    );

    return `${Math.floor(result).toLocaleString()}€`;
  },
};

const solicitousFiltrableColumns = [
  {
    key: "orizzonte",
    title: "Orizzonte",
    selectType: "select",
    defaultValue: "ultimo mese",
  },
  { key: "classe_cliente", title: "Classe cliente" },
  { key: "classe_fornitura", title: "Classe fornitura" },
  { key: "scaduto_scadere", title: "Scaduto/Scadere" },
  { key: "canale", title: "Canale" },
  { key: "contenuto", title: "Contenuto" },
  { key: "periodicita", title: "Periodicità", type: "number" },
];

const solicitousHistorySend = {
  id: "solicitous-history-send",
  title: "Totale solleciti inviati",
  description: "Totale numerico dei solleciti inviati",
  valueTitle: "Totale",
  filterableColumns: solicitousFiltrableColumns,
  getKpiValue: (data) => {
    if (!data || !data.length) {
      return 0;
    }

    const result = data.reduce((acc, { n_solleciti }) => acc + n_solleciti, 0);

    return result.toLocaleString();
  },
};
const solicitousHistorySendPaid = {
  id: "solicitous-history-send-paid",
  title: "Totale solleciti riscossi",
  description: "Totale numerico dei solleciti riscossi",
  valueTitle: "Totale",
  filterableColumns: solicitousFiltrableColumns,
  getKpiValue: (data) => {
    if (!data || !data.length) {
      return 0;
    }

    const result = data.reduce(
      (acc, { n_solleciti_pagati }) => acc + n_solleciti_pagati,
      0
    );

    return result;
  },
  percentage: (data) => {
    if (!data || !data.length) {
      return 0;
    }
    const n = data.reduce((acc, { n_solleciti }) => acc + n_solleciti, 0);

    const nPaid = data.reduce(
      (acc, { n_solleciti_pagati }) => acc + n_solleciti_pagati,
      0
    );

    return `${((nPaid / n) * 100).toFixed(0)}%`;
  },
};

const totalSolicitousHistorySend = {
  id: "total-solicitous-history-send",
  title: "Totale importo notificato",
  description: "",
  valueTitle: "Totale",
  filterableColumns: solicitousFiltrableColumns,
  getKpiValue: (data) => {
    if (!data || !data.length) {
      return 0;
    }

    const result = data.reduce(
      (acc, { importo_solleciti }) => acc + importo_solleciti,
      0
    );

    return `${Math.floor(result).toLocaleString()}€`;
  },
};
const totalSolicitousHistorySendPaid = {
  id: "total-solicitous-history-send-paid",
  title: "Totale importo riscosso",
  description: "",
  valueTitle: "Totale",
  filterableColumns: solicitousFiltrableColumns,
  getKpiValue: (data) => {
    if (!data || !data.length) {
      return 0;
    }

    const result = data
      .reduce(
        (acc, { importo_solleciti_pagati }) => acc + importo_solleciti_pagati,
        0
      )
      .toLocaleString();
    return `${result}€`;
  },

  percentage: (data) => {
    if (!data || !data.length) {
      return 0;
    }
    const tot = data.reduce(
      (acc, { importo_solleciti }) => acc + importo_solleciti,
      0
    );
    const totPaid = data.reduce(
      (acc, { importo_solleciti_pagati }) => acc + importo_solleciti_pagati,
      0
    );

    return `${((totPaid / tot) * 100).toFixed(0)}%`;
  },
};

const totalContracts = {
  id: "total-contracts",
  title: "Numero contratti in gestione",
  description: "",
  valueTitle: "Totale",
  filterableColumns: contractsFiltreableColumns,
  getKpiValue: (data) => {
    if (!data || !data.length) {
      return 0;
    }

    const result = data.reduce(
      (acc, { numero_contratti_in_gestione }) =>
        acc + numero_contratti_in_gestione,
      0
    );

    return `${result.toLocaleString()}`;
  },
};
const totalContractsNotReachable = {
  id: "total-contracts-2",
  title: "Numero contratti non contattabili",
  description: "",
  valueTitle: "Totale",
  filterableColumns: contractsFiltreableColumns,
  getKpiValue: (data) => {
    if (!data || !data.length) {
      return 0;
    }

    const result = data.reduce(
      (acc, { numero_contratti_non_contattabili }) =>
        acc + numero_contratti_non_contattabili,
      0
    );

    return `${result.toLocaleString()}`;
  },
};

export {
  openCredit,
  openCreditAging,
  totalSolicitousHistorySend,
  solicitousHistorySend,
  solicitousHistorySendPaid,
  totalSolicitousHistorySendPaid,
  totalContracts,
  totalContractsNotReachable,
};
